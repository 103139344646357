$animation-duration: .8s !default;
$animation-easing: ease !default;
$animation-amount: 40px !default;
$animation-delay: .2s !default;
$animation-appear-background: white !default;

%animation {
	transition: opacity $animation-duration linear, transform $animation-duration ease;

	.no-js & {
		transition: none !important;
		opacity: 1 !important;
		transform: translate(0) !important;
	}
}

// Generate transition delay classes of up to 10s
// Usage: .a-delay--1
@for $i from 1 through 10 {
	.a-delay--#{$i} {
		transition-delay: ($animation-delay * $i) + 0s !important;
	}
}

.a-fade-in-left {
	@extend %animation;

	opacity: 0;
	transform: translateX(-$animation-amount);

	.is-visible & {
		opacity: 1;
		transform: translate(0);
	}
}

.a-fade-in-up {
	@extend %animation;

	transform: translateY($animation-amount);
	opacity: 0;

	.is-visible & {
		opacity: 1;
		transform: translate(0);
	}
}

.a-fade-in-down {
	@extend %animation;

	transform: translateY(-$animation-amount);
	opacity: 0;

	.is-visible & {
		opacity: 1;
		transform: translate(0);
	}
}

.a-appear-in-left {
	@extend %animation;

	position: relative;

	&::after {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: $animation-appear-background;
		transform-origin: right;
		transition: transform 1s cubic-bezier(.86,0,.07,1) .2s;
		transition-delay: inherit;
		z-index: 2;
	}

	.is-visible & {
		&::after {
			transform: scaleX(0);
		}
	}
}
