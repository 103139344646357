// h1
@mixin type-style-01 {
	font-size: rem(35px);
	font-weight: 700;
	line-height: (40 / 35);

	@media (min-width: $break-grid-md) {
		font-size: rem(44px);
	}

	@media (min-width: $break-grid-lg) {
		font-size: rem(68px);
	}
}

// h2
@mixin type-style-02 {
	font-size: rem(30px);
	font-weight: 700;
	line-height: (36 / 30);

	@media (min-width: $break-grid-md) {
		font-size: rem(34px);
	}

	@media (min-width: $break-grid-lg) {
		font-size: rem(53px);
	}
}

// h3
@mixin type-style-03 {
	font-size: rem(26px);
	font-weight: 700;
	line-height: (32 / 26);

	@media (min-width: $break-grid-md) {
		font-size: rem(30px);
	}

	@media (min-width: $break-grid-lg) {
		font-size: rem(40px);
	}
}

// h4
@mixin type-style-04 {
	font-size: rem(23px);
	font-weight: 700;
	line-height: (28 / 23);

	@media (min-width: $break-grid-md) {
		font-size: rem(23px);
	}

	@media (min-width: $break-grid-lg) {
		font-size: rem(30px);
	}
}

// h5
@mixin type-style-05 {
	font-size: rem(20px);
	font-weight: 700;
	line-height: (24 / 20);

	@media (min-width: $break-grid-md) {
		font-size: rem(20px);
	}

	@media (min-width: $break-grid-lg) {
		font-size: rem(22px);
	}
}

// h6
@mixin type-style-06 {
	font-size: rem(18px);
	font-weight: 700;
	line-height: (22 / 20);

	@media (min-width: $break-grid-md) {
		font-size: rem(18px);
	}

	@media (min-width: $break-grid-lg) {
		font-size: rem(20px);
	}
}

// p
@mixin type-style-07 {
	font-size: rem(16px);
}

// lead
@mixin type-style-08 {
	font-size: rem(22px);
	font-weight: normal;
	line-height: (28 / 22);

	@media (min-width: $break-grid-md) {
		font-size: rem(24px);
	}

	@media (min-width: $break-grid-lg) {
		font-size: rem(26px);
	}
}
