.copy {
	h1 {
		@include type-style-01;
		margin-bottom: rem(38px);

		@media (min-width: $break-grid-md) {
			margin-bottom: rem(42px);
		}
	}

	h2 {
		@include type-style-02;
		margin-top: rem(80px);
		margin-bottom: rem(32px);

		@media (min-width: $break-grid-lg) {
			margin-bottom: rem(44px);
		}
	}

	h3 {
		@include type-style-03;
		margin-top: rem(80px);
		margin-bottom: rem(20px);

		@media (min-width: $break-grid-md) {
			margin-bottom: rem(28px);
		}

		@media (min-width: $break-grid-lg) {
			margin-bottom: rem(36px);
		}
	}

	h4 {
		@include type-style-04;
		margin-top: rem(72px);
		margin-bottom: rem(28px);
	}

	h5 {
		@include type-style-05;
		margin-top: rem(72px);
		margin-bottom: rem(20px);
	}

	h6 {
		@include type-style-06;
		margin-top: rem(60px);
		margin-bottom: rem(15px);
	}

	p,
	.lead,
	ul,
	ol {
		margin-bottom: rem(25px);

		@media (min-width: $break-grid-md) {
			margin-bottom: rem(30px);
		}
	}

	p {
		@include type-style-07;
	}

	.lead {
		@include type-style-08;
	}

	ul, ol {
		margin-bottom: rem(25px);
		padding-left: rem(40px);
	}

	ul {
		list-style-type: disc;
	}

	ol {
		list-style-type: decimal;
	}

	li {
		@include type-style-07;
		display: list-item;
		list-style-type: inherit;
	}

	a:not(.btn) {
		text-decoration: underline;
	}

	hr {
		height: rem(1px);
		width: 100%;
		margin-top: rem(40px);
		margin-bottom: rem(40px);
		background-color: black;
	}
}
