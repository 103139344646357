.icon {
	display: block;
}

.icon--plus {
	flex: 0 0 rem(18px);
	position: relative;
	height: rem(18px);
	width: rem(18px);
	margin-left: rem(10px);

	&::before,
	&::after {
		content: "";
		position: absolute;
		background: currentColor;
		transition: transform .25s ease-out;
	}

	&::before {
		top: 0;
		left: 50%;
		height: 100%;
		width: 2px;
		margin-left: -1px;
	}

	&::after {
		top: 50%;
		left: 0;
		height: 2px;
		width: 100%;
		margin-top: -1px;
	}

	.is-active & {
		&::before {
			transform: rotate(90deg);
		}

		&::after {
			transform: rotate(180deg);
		}
	}
}

.icon--file {
	height: rem(48px);
	width: rem(36px);
}

.icon--download {
	height: rem(20px);
	width: rem(21px);
}
