body {
	margin:0;
	color:#7e7b7b;
	font:15px/21px 'Open Sans', Arial, Helvetica, sans-serif;
	background:#f3f3f3;
	min-width:1064px;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: none;
}
img {border-style:none;}
a {
	text-decoration:none;
	color:#000;
}
a:hover {text-decoration:underline;}
a:active {background-color: transparent;}
input,
textarea,
select {
	font:100% Arial, Helvetica, sans-serif;
	vertical-align:middle;
	color:#000;
}
form, fieldset {
	margin:0;
	padding:0;
	border-style:none;
}
header, footer, article, section, hgroup, nav, figure { display: block; }
#wrapper{ overflow:hidden;}
.top-bar{overflow:hidden;}
.top-bar .holder{
	max-width:1064px;
	overflow:hidden;
	margin:0 auto;
	padding:19px 0 19px;
}
#wrapper .top-bar h1{
	margin:0;
	color:#2a2a2a;
	font-size:21px;
	line-height:24px;
	font-weight:300;
	padding:8px 0 0;
}
.logo{
	text-indent:-9999px;
	overflow:hidden;
}
.logo a{
	display:block;
	height:100%;
}
.top-bar .remondis-logo{
	text-indent:-9999px;
	overflow:hidden;
	float:right;
	background:url(../images/sprite.png) no-repeat -623px -233px;
	width: 177px;
	height: 41px;
	margin:0 0 0 10px;
}
.top-bar .remondis-logo a{
	height:100%;
	display:block;
}
.slider{
	overflow:hidden;
	position:relative;
}
.flexslider .slides {
	margin:0;
	padding:0;
	list-style:none;
}
.flexslider .flex-direction-nav,
.flexslider .flex-control-nav{
	display: none;
}
.flexslider .slides li{
	overflow:hidden;
	height: 580px;
}
.flexslider .slides .image-holder{
	position:relative;
	left:50%;
	float:left;
	min-width:2000px;
}
.flexslider .slides .image-area{
	min-width:2000px;
	position:relative;
	left:-50%;
	float:left;
}
.flexslider .slides img{
	display:block;
	width:100%;
}
.visual .logo-holder{
	position:absolute;
	top:50%;
	left:0;
	right:0;
	z-index: 9999;
	margin-top: -169px;
}
.visual .logo-holder .holder{
	max-width:1064px;
	overflow:hidden;
	margin:0 auto;
	padding:0;
}
.visual .logo{
	background:url(../images/sprite.png) no-repeat -14px -8px;
	width: 236px;
	height: 266px;
	float:left;
	margin-left: 4%
}
.visual {
	overflow:hidden;
	position:relative;
}
#main{
	margin:0 auto 73px;
	max-width:1064px;
}
.council-area:after,
#main:after{
	content:"";
	display:block;
	clear:both;
}
.council-area{
	position:relative;
	margin:-72px 0 75px;
	z-index:9999;
}
#wrapper .council-area h1{
	margin:0;
	color:#1f8db1;
	font-size:16px;
	line-height:19px;
	display:none;
	text-align:center;
}
.council-area .post:first-child{ float:left;}
.council-area .post{
	float:right;
	width:49%;
	border-radius: 6px;
	background-color: #fff;
	border: solid 1px #d6d3d3;
}
.council-area .posts{
	overflow:hidden;
	margin:21px 0;
	border-radius:5px;
	box-shadow: 0 2px 4px rgba(0,0,0,.15);
	background: #fff;
}
.council-area .post.second .logo-area{padding:43px 0 0;}
.council-area .logo-area{
	float:left;
	background:#fff;
	text-align:center;
	padding:31px 0 0;
	width: 176px;
	border-radius:5px;
}
.council-area .post .logo{
	background:url(../images/sprite.png) no-repeat -856px -161px;
	width: 124px;
	height: 122px;
	margin:0 auto;
	display:inline-block;
	vertical-align:middle;
}
.council-area .post.second .logo-area{ float:right;}
.council-area .post.second .logo{
	background-position:-37px -353px;
	width: 131px;
	height: 106px;
}
.council-area .post .image-holder{
	overflow:hidden;
	max-width:345px;
}
.council-area .post.second .image-holder{border-radius:5px 0 0 5px;}
.council-area .post.second .image-holder img{border-radius:5px 0 0 5px;}
.council-area .post .image-holder img{
	display:block;
	width:100%;
	border-radius:0 5px 5px 0;
}
.council-area  .btn-council{
	position:absolute;
	top:50%;
	left:50%;
	margin:-119px 0 0 -119px;
	background:url(../images/sprite.png) no-repeat -356px -303px;
	width: 238px;
	height: 238px;
	text-indent:-9999px;
	overflow:hidden;
}
.intro{
	overflow:hidden;
	text-align:center;
	font-size:18px;
	line-height:26px;
	font-weight:300;
	color:#2a2a2a;
	padding:0 80px 54px;
}
.intro p{ margin:0 0 10px;}
h1{
	margin:0 0 16px;
	font-size:26px;
	line-height:29px;
	color:#2a2a2a;
	font-weight:900;
}
.intro h1{
	word-spacing: 2px;
	letter-spacing: -0.8px;
}
.intro h1 span{ color:#fe0000;}
.feature{
	overflow:hidden;
	margin:0 -0.7%;
}
.feature .post{
	display:inline-block;
	vertical-align:top;
	background:#fff;
	border-right:1px solid #d6d3d3;
	border-left:1px solid #d6d3d3;
	border-top:1px solid #d6d3d3;
	border-bottom:0;
	box-shadow:0 5px 0 #fe0000;
	border-radius:5px 5px 0 0;
	text-align:center;
	width:31.6%;
	margin:0 0.7% 30px;
	padding:0 0 52px;
	box-sizing: border-box;
	position:relative;
}
.feature .post img{
	display:block;
	width:100%;
	margin:0 0 26px;
	border-radius: 5px 5px 0 0;
}
h2{
	margin:0 0 14px;
	color:#fe0000;
	font-size:22px;
	line-height:25px;
	font-weight:900;
}
h2 a{color:#fe0000;}
h2 a:hover {
	color:#2A2A2A;
	text-decoration: none;
}
.feature .post .btn-more{
	position:relative;
	color:#fe0000;
	font-size:15px;
	line-height:18px;
	display:inline-block;
	vertical-align:top;
	font-weight:700;
	padding:0 17px 0 0;
}
.feature .post .btn-more:hover{
	text-decoration: none;
}
.feature .post .btn-more:after{
	background:url(../images/sprite.png) no-repeat -932px -415px;
	position:absolute;
	top:50%;
	right:0;
	width: 9px;
	margin:-5px 0 0 0;
	height: 12px;
	content:"";
	display:block;
	clear:both;
	transition: all .2s ease;
}
.feature .post .btn-more:hover:after{
	right:-4px;
}
.feature .post .text p{ margin:0 0 12px;}
.feature .post .text{
	padding:0 20px 0;
	overflow:hidden;
	line-height:22px;
}
.feature .post .text .btn-holder{
	position:absolute;
	bottom:8%;
	right:0;
	left:0;
}
#footer{
	overflow:hidden;
	background:#393b3b;
	font-size:13px;
	line-height:16px;
}
#footer .holder{
	max-width:1064px;
	overflow:hidden;
	margin:0 auto;
	padding:15px 0 10px;
}
#footer .holder .info{ overflow:hidden}
#footer .copyright{
	float:left;
	color:#fff;
	padding:0 6px 0 0;
	word-spacing:-1px;
	min-width: 170px;
}
#footer .copyright a{color:#fff;}
.footer-nav{
	margin:0;
	padding:0;
	list-style:none;
	color:#959595;
	overflow:hidden
}
.footer-nav li{
	float:left;
	border-left:1px solid #4c5050;
	padding:0 9px;
	margin:0 0 8px;
}
.footer-nav li a{color:#959595;}
.footer-nav li a:hover{color:#ccc; text-decoration: none;}
#footer .logo-redback{
	float:right;
	background:url(../images/sprite.png) no-repeat -856px -413px;
	width: 17px;
	height: 16px;
	text-indent:-9999px;
	overflow:hidden;
	margin:0 0 0 10px;
}
#footer .logo-redback a{
	display:block;
	height:100%;
}
.skip a{
	position:absolute;
	left:-10000px;
	top:auto;
	width:1px;
	height:1px;
	overflow:hidden;
}
.skip a:focus{
	position:static;
	width:auto;
	height:auto;
}
@media only screen and (max-width: 1064px){
	body {min-width:320px;}
}
@media only screen and (max-width: 1087px){
	.top-bar .holder,
	.visual .logo-holder .holder,
	#main,
	#footer .holder{
		max-width: inherit;
		margin:0 1%;
	}
}
@media only screen and (max-width: 1051px){
	.council-area .post .logo{
		background-position:-1010px -180px;
		width: 86px;
		height: 86px;
	}
	.council-area .post.second .logo{
		background-position:-203px -375px;
		width: 91px;
		height: 74px;
	}

}
@media only screen and (max-width: 1023px){
	.council-area .btn-council{
		background-position:-623px -345px;
		width: 168px;
		height: 168px;
		margin:-84px 0 0 -84px;
	}
}
@media only screen and (max-width: 896px){
	.council-area .logo-area,
	.council-area .post.second .logo-area{padding: 27px 0 0;}
	.top-bar .holder{ padding:19px 13px;}
	.visual .logo{
		background-position:-282px -86px;
		width: 166px;
		height: 188px;
	}
	.flexslider .slides li{ height:348px;}
	.visual .logo{ margin: 0 0 0 12px;}
	.visual .logo-holder {margin-top: -124px;}
	.visual .logo-holder .holder{padding: 0;}
	.council-area{margin:-59px 12px 59px;}
	.council-area .logo-area{width: 119px;}
	.intro{ padding: 0 0 54px;}
}

@media only screen and (max-width: 813px){
	.feature .post{
		margin: 0 0.65% 30px;
	}
}

@media only screen and (max-width: 767px){
	.council-area .posts{
		background: none;
	}
	#footer .copyright{ padding:0 4px 0 0;}
	.flexslider .slides .image-holder{
		min-width:768px;
	}
	.flexslider .slides .image-area{
		min-width:768px;
	}
	.feature .post{
		width: 30%;
		margin: 0 11px 30px;
	}
	.feature{ margin:0 1.4%;}
	#wrapper h2{
		/*font-size:18px;
		line-height:21px;
		margin:0 0 7px;*/
	}
	.feature .post .text{
		line-height: 18px;
		font-size:13px;
		padding:0 16px 0;
	}
	.feature .post img{ margin: 0 0 18px;}
	.feature .post .btn-more{
		font-size:13px;
		line-height:16px;
	}
	.feature .post{
		padding: 0 0 35px;
		margin: 0 1.4% 30px;
	}
	.feature .post .text .btn-holder{ bottom: 7%;}
	#main{ margin:0 1% 40px;}
	#footer .holder{ padding:15px 12px 18px;}
}


@media only screen and (max-width: 765px){
	.flexslider .slides .image-holder,
	.flexslider .slides .image-area{
		left:inherit;
		position:inherit;
		overflow:hidden;
		min-width:inherit;
		float:none;
	}
	.flexslider .slides li{ height:inherit;}
}
@media only screen and (max-width: 760px){
	.council-area .post,
	.council-area .post:first-child{
		width:100%;
		border: solid 1px #d6d3d3;
		box-shadow: 0 2px 4px rgba(0,0,0,.15);
		background-color: #fff;
		border-radius: 5px;
		margin:0 auto 15px;
		float:none;
	}
	.council-area .post.second .image-holder img{border-radius:0 5px 5px 0;}
	.council-area .post .image-holder img{
		display:block;
		width:100%;
		border-radius:0 5px 5px 0;
	}
	.council-area .post.second .image-holder{border-radius:0 5px 5px 0;}
	.council-area .btn-council{ display:none;}
	.council-area .post .image-holder{ max-width:inherit;}
	.council-area .post .image-holder img{
		max-width:100%;
		width:inherit;
	}
	.council-area .post .image-holder img{ float:right;}
	.council-area .post.second .logo-area{ float:left;}
	.council-area{ margin:0 0 12px;}

	.visual .logo-holder{
		margin-top: -94px;
	}
	#wrapper .council-area h1{ display:block;}
	.council-area .logo-area,
	.council-area .post.second .logo-area {
		padding: 0;
	}
	.council-area .logo-area {width:50%;}
	.logo-content{
		display:table;
		width:100%;
		height:100%;
	}
	.logo-holder{
		display:table-cell;
		vertical-align:middle;
	}
	/*.visual .logo{
		background-position:-495px -170px;
		width: 93px;
		height: 104px;
	}*/
	.top-bar .remondis-logo{
		background-position:-645px -180px;
		width: 124px;
		height: 29px;
		display:inline-block;
		vertical-align:top;
		float:none;
		margin:0 0 6px;
	}
	.top-bar .holder{
		text-align:center;
		padding:21px 13px 18px;
	}
	.visual{ margin:0 0 23px;}
	.council-area .posts{
		padding:12px 15px 0;
		box-shadow:none;
	}
	#wrapper h2{
		/*font-size:22px;
		line-height:25px;
		margin:0 0 13px;*/
	}
	.feature .post .text{
		font-size:15px;
		line-height:22px;
		padding:0 8px;
	}
	.feature .post .btn-more{
		font-size:15px;
		line-height:18px;
	}
	.feature .post{
		width:100%;
		height:auto !important;
		padding:0 0 20px;
		margin:0 0 19px;
	}
	.feature .post .text .btn-holder{
		position:inherit;
		top:inherit;
		bottom:inherit;
	}
	#footer .holder .info{
		float:none;
		overflow:hidden;
	}
	#footer .holder{
		text-align:center;
		padding: 15px 0 18px;
		position:relative;
	}
	#footer .copyright,
	.footer-nav,
	#footer .logo-redback{
		float:none;
		display:block;
		overflow:hidden;
		vertical-align:top;
	}
	.footer-nav li{
		float:none;
		display:inline-block;
		vertical-align:top;
		padding:0 4px;
	}
	#footer .copyright{ margin:0 0 8px;}
	.footer-nav{ margin:0 0 15px;}
	.footer-nav li:first-child{ border:none;}
	#footer .logo-redback{
		bottom: 10px;
		left: 50%;
		margin: 0 0 0 -7px;
		position: absolute;
	}
	#wrapper .top-bar h1{ line-height: 28px;}
	/*.visual .logo-holder .holder{  padding: 6.2% 0 0;}*/
	/*.visual .logo { margin: 0 0 0 16px;}*/
	.feature .post .text p {
		margin: 0 0 14px;
	}
	#main {margin: 0 1% 13px;}
}



@media only screen and (max-width: 500px){
	.visual .logo{
		background-position:-495px -170px;
		width: 93px;
		height: 104px;
	}

	.visual .logo-holder{
		margin-top: -52px;
	}
}




@media only screen and (max-width: 453px){
	.council-area .logo-area {width: 118px;}
}
@media only screen and (max-width: 321px){
	.flexslider .slides .image-holder{
		min-width:319px;
	}
	.flexslider .slides .image-area{
		min-width:319px;
	}
	.flexslider .slides li{ height:144px;}
	.council-area .post .image-holder img{
		max-width:100%;
	}
	.intro {padding: 0 11px 30px;}
	.feature {margin:0 17px 0 13px;}
	.feature .post img {
		margin: 0 0 23px;
	}
	.council-area .posts{	margin:0;}
}

.cp #main .holder {
	max-width: 720px;
	margin: 6% auto 9%;
	padding: 0 20px;
}
@media only screen and (max-width: 440px){
	.cp #main .holder {
		margin: 6% auto 18%;
	}
}

p {
	margin: 0 0 18px;
}

.cp .visual {
	max-width: 1064px;
	margin: 0 auto;
}

.cp .visual img {
	width: 100%;
}


.cp .visual .logo{
	background-position:-282px -86px;
	width: 166px;
	height: 188px;
	margin-left: 4%;
}

.cp .visual .logo-holder {
	top: 50%;
	margin-top: -94px;
}

.cp .visual .logo-holder .holder {
	padding: 0;
}

@media only screen and (max-width: 520px){

	.cp .visual .logo-holder {
		margin-top: -52px;
	}
	.cp .visual .logo{
		background-position:-495px -170px;
		width: 93px;
		height: 104px;
	}
}

.copy h1 {
	font-size: 60px;
	/*75*/
	font-weight: 300;
	line-height: 1.0667em;
	/*80*/
	margin: 0 0 40px;
	text-align: center;
}
@media only screen and (max-width: 767px) {

	.copy h1 {
		font-size: 36px;
		line-height: 38px;
		margin-bottom: 20px;
	}
}

.copy h2 {
	font-size: 1.7333em;
	/*26*/
	font-weight: 800;
	line-height: 1.1538em;
	/*30*/
	margin: 0 0 17px;
}

@media only screen and (max-width: 767px) {

	.copy h2 {
		font-size: 18px;
		line-height: 22px;
		margin: 0 0 10px;
	}
}