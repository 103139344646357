//---------------------------------------------------------------------------
//	Container
//---------------------------------------------------------------------------

.l-container {
	max-width: rem(1640px);
	margin: 0 auto;
	padding: 0 $l-container-padding-xs;

	@media (min-width: $break-grid-lg) {
		padding: 0 $l-container-padding-lg;
	}

	@media (min-width: $break-grid-xl) {
		padding: 0 $l-container-padding-xl;
	}
}

.l-container--sm {
	max-width: rem(800px);
}

.l-container--md {
	max-width: (rem(968px) + ($l-container-padding-lg * 2));
}

.l-container--lg {
	max-width: (rem(1400px) + ($l-container-padding-xl * 2));
}


//---------------------------------------------------------------------------
//	Grid
//  Add grid cell modifiers as required
//---------------------------------------------------------------------------

.l-grid {
	display: flex;
	flex-wrap: wrap;
	margin: 0 (-$l-grid-padding-xs);

	@media (min-width: $break-grid-xl) {
		margin: 0 (-$l-grid-padding-xl);
	}
}

.l-grid__cell {
	width: 100%;
	padding: 0 $l-grid-padding-xs;

	@media (min-width: $break-grid-xl) {
		padding: 0 $l-grid-padding-xl;
	}
}

// XS

.l-grid__cell--50 {
	width: 50%;
}

// SM

@media (min-width: $break-grid-sm) {
	.l-grid__cell--50-at-sm {
		width: 50%;
	}
}

// MD

@media (min-width: $break-grid-md) {
	.l-grid__cell--33-at-md {
		width: 33.33%;
	}

	.l-grid__cell--50-at-md {
		width: 50%;
	}

	.l-grid__cell--66-at-md {
		width: 66.66%;
	}
}

// LG

@media (min-width: $break-grid-lg) {
	.l-grid__cell--33-at-lg {
		width: 33.33%;
	}

	.l-grid__cell--50-at-lg {
		width: 50%;
	}

	.l-grid__cell--66-at-lg {
		width: 66.66%;
	}
}

// XL

@media (min-width: $break-grid-xl) {
	.l-grid__cell--33-at-xl {
		width: 33.33%;
	}

	.l-grid__cell--50-at-xl {
		width: 50%;
	}

	.l-grid__cell--66-at-xl {
		width: 66.66%;
	}
}

.l-section {
	margin-bottom: rem(40px);

	@media (min-width: $break-grid-md) {
		margin-bottom: rem(60px);
	}

	@media (min-width: $break-grid-lg) {
		margin-bottom: rem(80px);
	}

	@media (min-width: $break-grid-xl) {
		margin-bottom: rem(100px);
	}
}
